import React, { useEffect, useMemo } from "react";
import { createPortal } from "react-dom";
import "../Assets/Styles/modal.scss";
const modalRootElement = document.querySelector("#modal");

const Modal = (props) => {
  const { open, onClose } = props;
  const element = useMemo(() => document.createElement("div"));

  if (open) {
  }
  useEffect(() => {
    if (open) {
      modalRootElement.appendChild(element);
      return () => {
        modalRootElement.removeChild(element);
      };
    }
  });
  if (open) {
    return createPortal(
      <div className="modalBackground" onClick={onClose}>
        <div className="modalCard">{props.children}</div>
      </div>,
      element
    );
  }
  return null;
};
export { Modal };
